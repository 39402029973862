import React from "react"
import Layout from "../components/layout"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"
import Gallery from "../components/gallery"
import { graphql } from "gatsby"
import SEO from "../components/seo"

const Lokacija = ({ data }) => {
  const intl = useIntl()
  const slike = data.allFile.edges
  return (
    <Layout>
      <SEO lang={intl.locale} title={intl.formatMessage({ id: "lokacija" })} />
      <div className="section blue-background lokacija">
        <div className="content">
          <div className="card">
            <h1>{intl.formatMessage({ id: "lokacija" })}</h1>
            <p>
              <FormattedMessage
                id="lokacija.tekst"
                values={{
                  p: (...chunks) => <p>{chunks}</p>,
                  br: <br />,
                }}
              />
            </p>
            <h2 style={{ marginTop: "3rem" }}>
              {intl.formatMessage({ id: "lokacija.upute" })}
            </h2>
            <div style={{ margin: "1.5rem 0" }}>
              <iframe
                width="100%"
                height="400"
                src="https://www.youtube.com/embed/o3AVv5wBaOo"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <h2 style={{ marginTop: "3rem" }}>
              {intl.formatMessage({ id: "lokacija.upute2" })}
            </h2>
            <div style={{ margin: "1.5rem 0" }}>
              <iframe
                width="100%"
                height="400"
                src="https://www.youtube.com/embed/A5Mt81kGwmY"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>

            <div
              style={{
                width: "100%",
                margin: "3rem 0",
              }}
            >
              <iframe
                title="Apartaments Erik Map"
                width="100%"
                height="400"
                src="https://maps.google.com/maps?width=100%&amp;height=500&amp;hl=en&amp;q=Apartments%20and%20Rooms%20%22Erik%22%2C%20Rovinj%2C%20Croatia+(Apartments%20and%20Rooms%20Erik)&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=B&amp;output=embed"
                frameBorder="0"
                scrolling="no"
                marginHeight="0"
                marginWidth="0"
              >
                <a href="https://www.maps.ie/draw-radius-circle-map/">
                  km radius map
                </a>
              </iframe>
            </div>

            <h2>Rovinj</h2>
            <p style={{ marginBottom: "1.5rem" }}>
              <FormattedMessage
                id="pocetna.lokacija"
                values={{
                  p: (...chunks) => <p>{chunks}</p>,
                  br: <br />,
                }}
              />
            </p>

            <Gallery slike={slike} />

            <h2 style={{ marginTop: "3rem", marginBottom: "1.5rem" }}>
              TUI Nordic
            </h2>
            <a
              style={{ display: "block", marginBottom: "0.5rem" }}
              target={"_blank"}
              rel="noopener noreferrer"
              href="https://www.tui.no/feriereiser/kroatia/"
            >
              TUI Nordic NO
            </a>
            <a
              style={{ display: "block", marginBottom: "0.5rem" }}
              target={"_blank"}
              rel="noopener noreferrer"
              href="https://www.tui.fi/matkat/kroatia/"
            >
              TUI Nordic FI
            </a>
            <a
              style={{ display: "block", marginBottom: "0.5rem" }}
              target={"_blank"}
              rel="noopener noreferrer"
              href="https://www.tui.se/resa/kroatien/"
            >
              TUI Nordic SE
            </a>
            <a
              style={{ display: "block" }}
              target={"_blank"}
              rel="noopener noreferrer"
              href="https://www.tui.dk/rejse/kroatien/"
            >
              TUI Nordic DK
            </a>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Lokacija

export const query = graphql`
  query {
    allFile(
      filter: { relativeDirectory: { regex: "/lokacija/" } }
      sort: { fields: relativeDirectory, order: DESC }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(sizes: "(min-width: 10px) 2000px, 2000px", maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
          id
          relativeDirectory
        }
      }
    }
  }
`
